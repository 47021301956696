<template>
  <div class="container">
    <div slot="title" class="title">新增机构</div>
    <el-form
      ref="form"
      :model="form"
      label-width="12rem"
      size="small"
      style="display: flex; flex-wrap: wrap"
      :rules="rules"
    >
      <div class="aaa">
        <el-form-item label="机构名称" required prop="mechanism_name">
          <el-input
            v-model="form.mechanism_name"
            :placeholder="temp.mechanism_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="form.type" @change="getMechanismType">
            <el-radio label="partners">合作商</el-radio>
            <el-radio label="teach_paypal">机构</el-radio>
            <el-radio label="分店">分店</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="是否分店" v-if="form.type== 'teach_paypal'">
            <el-checkbox v-model="if_part"></el-checkbox>
          </el-form-item> -->

        <el-form-item
          v-if="form.type == '分店'"
          label="所属机构"
          prop="education_id"
          key="education_id"
        >
          <el-select
            v-model="form.education_id"
            :remote-method="getMechanismList"
            remote
            filterable
            @change="log"
            :placeholder="getMechanismName(temp.education_id) || '请选择机构'"
          >
            <el-option
              v-for="item in mechanism_list"
              :key="item.id"
              :label="item.mechanism_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否独立负责人" v-if="form.type == '分店'">
          <el-checkbox v-model="if_admin"></el-checkbox>
        </el-form-item>
        <el-form-item label="是否推荐">
          <el-radio-group v-model="form.is_recommend">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择地区" required prop="city" key="city">
          <addr-select
            :clear="if_clear"
            @getform="addMecAddr"
            :addr="{
              province: temp.province,
              city: temp.city,
              district: temp.district,
            }"
          ></addr-select>
        </el-form-item>

        <!-- <el-form-item label="详细地址" required prop="mechanism_addr" key="mechanism_addr">
            <search-addr :clear="if_clear" @transAddr="addMecAddrD" :addr='temp.mechanism_addr'></search-addr>
          </el-form-item> -->
        <el-form-item
          label="机构电话"
          required
          prop="mechanism_telephone"
          key="mechanism_telephone"
        >
          <el-input
            v-model="form.mechanism_telephone"
            :placeholder="temp.mechanism_telephone"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="负责人姓名"
          required
          v-if="
            form.type == 'teach_paypal' || (form.type == '分店' && if_admin)
          "
          prop="contacts"
          key="contacts"
        >
          <el-input
            v-model="form.contacts"
            :placeholder="temp.contacts"
          ></el-input>
        </el-form-item>
        <el-form-item label="负责人职称" v-if="form.type == '分店' && if_admin">
          <el-input
            v-model="form.contacts_title"
            :placeholder="temp.contacts_title"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          required
          v-if="
            form.type == 'teach_paypal' || (form.type == '分店' && if_admin)
          "
          prop="contact_telephone"
          key="contact_telephone"
        >
          <el-input
            v-model="form.contact_telephone"
            :placeholder="temp.contact_telephone"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="支付宝收款账户" required prop="payee_logon_id"> -->
        <el-form-item label="支付宝收款账户">
          <el-input
            v-model="form.payee_logon_id"
            :placeholder="temp.payee_logon_id"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="机构类别" required>
            <el-select
              v-model="form.categories"
              @focus="searchCatName"
              remote
              clearable
              :remote-method="searchCatName"
            >
              <el-option
                v-for="(item, index) in categories_list"
                :key="index"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="选择子类目" required>
          <el-popover
            placement="top-start"
            title="标题"
            trigger="click"
            v-model="visible"
          >
            <el-button slot="reference">{{
              haveCate.length == 0 ? "选择子类目" : haveCate.join(",")
            }}</el-button>

            <el-transfer
              filterable
              filter-placeholder="请选择绑定的科目"
              v-model="haveCate"
              :data="categories_child_list"
              :props="{
                key: 'name',
                label: 'name',
              }"
              :titles="['所有类目', '拥有类目']"
            >
            </el-transfer>
          </el-popover>
        </el-form-item>
        <el-form-item label="告警课节">
          <el-input
            v-model="form.warning_hours"
            placeholder="剩余多少课节时发出告警"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="机构简介">
          <el-input
            style="width: inherit"
            type="textarea"
            v-model="form.introduction_content"
          ></el-input>
        </el-form-item>
      </div>
      <div class="aaa">
        <el-form-item label="机构logo">
          <avatar-uploader
            @getImgUrl="getFaceView"
            :clear="if_clear"
            :url="temp.logo"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item
          label="营业执照"
          required
          prop="support_means"
          ref="support"
        >
          <avatar-uploader
            @getImgUrl="getSupportImg"
            :clear="if_clear"
            :url="temp.support_means"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="机构资质">
          <images-uploader
            @getImgUrl="get_quali_pic_url"
            :clear="if_clear"
            :urls="temp.qualifications_pic"
          >
          </images-uploader>
        </el-form-item>
        <el-form-item label="简介图片">
          <images-uploader
            @getImgUrl="get_introduce_pic_url"
            :clear="if_clear"
            :urls="temp.introduction_pic"
          >
          </images-uploader>
        </el-form-item>
        <el-form-item label="机构门面图">
          <images-uploader
            @getImgUrl="get_facade_view_url"
            :clear="if_clear"
            :urls="temp.facade_view"
          >
          </images-uploader>
        </el-form-item>
      </div>
    </el-form>
    <span class="dialog-footer">
      <el-button type="primary" @click="submitForm()">添 加</el-button>
    </span>
    <!-- 修改 -->
  </div>
</template>

<script>
// import searchAddr from './searchAddr.vue'
export default {
  watch: {
    temp: {
      deep: true,
      handler(newV, oldV) {
        if (Object.keys(newV).length) {
          this.haveCate = newV.categories_child
            ? newV.categories_child.split("/")
            : [];
          if (newV.education_id) {
            this.if_part = true;
          }
          if (newV.contact_telephone) {
            this.if_admin = true;
          }
          this.$set(this.form, "type", newV.type);
          this.$set(this.form, "is_recommend", newV.is_recommend);
        }
      },
    },
    // if_part(newV, oldV) {
    //   if (!newV) {
    //     this.if_admin = false

    //     this.$set(this.form, 'education_id', null)
    //   }
    // },
    if_admin(newV, oldV) {
      if (!newV) {
        this.$set(this.form, "contact_telephone", null);
        this.$set(this.form, "contacts", null);
      }
    },
  },
  components: {
    // searchAddr,
  },
  data() {
    var checkSupport = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请上传营业执照!"));
      }
      callback();
    };
    return {
      dialogVisible: false,
      address: {},
      if_admin: false,
      if_clear: true,
      categories_list: [],
      if_part: false,
      mechanism_list: [],
      categories_child_list: [],
      haveCate: [],
      visible: false,
      form: {},
      temp: {},
      rules: {
        mechanism_name: [
          { required: true, message: "机构名不能为空!", trigger: "blur" },
          { required: true, message: "机构名不能为空!", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择机构类型!", trigger: "change" },
        ],
        mechanism_id: [
          { required: true, message: "请选择机构!", trigger: "blur" },
          { required: true, message: "请选择机构!", trigger: "change" },
        ],
        city: [
          { required: true, message: "请选择行政区域!", trigger: "change" },
        ],
        mechanism_addr: [
          { required: true, message: "请选择详细地址!", trigger: "change" },
        ],
        categories_child: [
          { required: true, message: "请选择类目!", trigger: "change" },
        ],
        mechanism_telephone: [
          { required: true, message: "请输入机构电话!", trigger: "change" },
          { required: true, message: "请输入机构电话!", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请填入负责人姓名!", trigger: "blur" },
          { required: true, message: "请填入负责人姓名!", trigger: "change" },
        ],
        contact_telephone: [
          { required: true, message: "请填入负责人联系方式!", trigger: "blur" },
          {
            required: true,
            message: "请填入负责人联系方式!",
            trigger: "change",
          },
        ],
        payee_logon_id: [
          { required: true, message: "请填入支付宝收款账号!", trigger: "blur" },
          {
            required: true,
            message: "请填入支付宝收款账号!",
            trigger: "change",
          },
        ],
        support_means: [{ validator: checkSupport, trigger: "change" }],
        education_id: [
          { required: true, message: "请选择所属机构!", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getCategoriesList();
  },

  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.addNewOrg();
        } else {
          this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateDialog() {
      return this.updateVisible;
    },
    popoverHide() {
      console.log(1);
      this.visible = false;
    },
    getMechanismType(v) {
      this.is_part = false;
      this.is_admin = false;
    },
    log(id) {
      console.log(id);
    },
    getMechanismName(id) {
      let a = this.mechanism_list.find((item) => {
        return item.id == id;
      });
      if (a) {
        return a.mechanism_name;
      } else return null;
    },
    getSupportImg(v) {
      this.$set(this.form, "support_means", v);
      this.$refs.support.$emit("el.form.change", this.form.support_means);
    },
    getFaceView(v) {
      this.$set(this.form, "mechanism_logo", v);
    },
    get_quali_pic_url(v) {
      this.$set(this.form, "qualifications_pic", v);
    },
    get_facade_view_url(v) {
      this.$set(this.form, "facade_view", v);
    },
    get_introduce_pic_url(v) {
      this.$set(this.form, "introduction_pic", v);
    },
    // 新增机构地址
    addMecAddr(addr) {
      console.log(addr);
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
    },
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "课程",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    searchCatName(query) {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            type: 1,
            status: 2,
            source: "课程",
          },
        })
        .then((res) => {
          // console.log(res);
          this.categories_list = res.data.data;
        });
    },
    // 新增机构详细地址
    addMecAddrD(data) {
      // console.log(data);
      this.$set(this.form, "mechanism_addr", data.address);
      this.$set(this.form, "latitude", data.location.lat);
      this.$set(this.form, "longitude", data.location.lng);
    },
    // 新增弹窗关闭确认
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.form = {};
          // this.if_part = false
          this.dialogVisible = false;
          this.$emit("close", { if_change: false });
          this.if_clear = true;
          done();
        })
        .catch((_) => {
          console.log(_);
        });
    },
    getMechanismList(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "teach_paypal",
            status: 2,
            // is_cooperation: true,
            currentPage: 1,
            pageSize: 10,
            mechanism_name: typeof query == "string" ? query : null,
            categories: "体育运动",
            parent_id: 0,
            // source:'体育宝',
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.mechanism_list = res.data.data.rows;
        })
        .catch((rej) => {});
    },
    // 新增机构
    addNewOrg() {
      this.if_clear = false;
      // console.log(this.form);
      // if (!this.form.mechanism_name) {
      //   return this.$message({ type: 'error', message: '请填写机构名称!' })
      // }
      // if (!this.form.type == '场馆' && !this.form.education_id) {
      //   return this.$message({ type: 'error', message: '请选择所属机构!' })
      // }
      // if (!this.address.city) {
      //   return this.$message({ type: 'error', message: '请选择正确的省市!' })
      // }

      // if (!this.form.mechanism_addr) {
      //   return this.$message({ type: 'error', message: '请选择详细地址!' })
      // }
      // // if (!this.form.mechanism_telephone) {
      // //   return this.$message({ type: 'error', message: '请填写机构电话!' })
      // // }
      // if (!this.form.contacts && this.if_admin) {
      //   return this.$message({ type: 'error', message: '请填写负责人!' })
      // }
      // if (!this.form.contacts && this.if_admin) {
      //   return this.$message({ type: 'error', message: '请填写负责人职称!' })
      // }
      // if (!this.form.contact_telephone && this.if_admin) {
      //   return this.$message({
      //     type: 'error',
      //     message: '请填写负责人联系方式!',
      //   })
      // }
      // if (!this.haveCate.length == 0) {
      //   return this.$message({ type: "error", message: "请选择机构子类目!" });
      // }

      // if (!this.form.support_means) {
      //   return this.$message({ type: 'error', message: '请上传营业执照!' })
      // }
      // if (!this.form.qualifications_pic) {
      //   return this.$message({ type: 'error', message: '请上传机构资质材料!' })
      // }
      let url;
      if (this.form.type == "分店" && !this.if_admin) {
        url = "/user/mastermechanism/insertAppointmentMechanismInfoBranches";
      } else {
        url = "/user/mastermechanism/insertAppointmentMechanismInfo";
      }
      this.form.categories = "体育运动";
      this.form.is_cooperation = this.form.type == "分店" ? true : null;
      this.$set(
        this.form,
        "mechanism_addr",
        this.form.province +
          this.form.city +
          this.form.district +
          this.form.mechanism_addr
      );
      // this.is_support_teach_paypal = true
      this.form.categories_child = this.haveCate.join(",");
      this.form.status = 2;
      this.form.source = "体育宝";
      let data = { ...this.form };
      if (this.if_admin) {
        data.type = "分店1";
      }
      this.$axios.post(url, data).then((res) => {
        // this.$refs.addrSelect.clear();
        if (res.data.code != 0) {
          this.$message({ type: "error", message: res.data.message });
        } else {
          this.$message({ type: "success", message: "添加成功" });
          // this.getOrgList()
          // this.getOrgTotal()
          this.dialogVisible = false;
          // console.log(res);
          this.isLoading = true;
          // this.$message({ type: "success", message: "添加成功!" });
          this.form = {};
          this.if_clear = true;
        }
      });
    },
    update() {
      this.form.id = this.temp.id;
      this.form.categories_child =
        this.haveCate != [] ? this.haveCate.join(",") : null;
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.form = {};
            this.temp = {};
            this.$emit("close", { if_change: true });
            this.$message({ type: "warning", message: "修改成功!" });
          }
        });
    },
  },
};
</script>

<style lang="less">
.aaa {
  width: 50%;
  @media screen and(max-width: 768px) {
    width: 100%;
  }
}
</style>
